import type {MetaDescriptor} from '@remix-run/node';

interface MetaTagsProps {
  title: string;
  description?: string;
  keywords?: string[];
  image?: string;
}

export function generateMetaTags({
  title,
  description,
  image = '/og.png',
  keywords = [],
}: MetaTagsProps): MetaDescriptor[] {
  const metaTags: MetaDescriptor[] = [];

  metaTags.push({title: `${title} | Observatory`});
  metaTags.push({name: 'og:title', content: title});
  metaTags.push({name: 'og:site_name', content: 'Observatory'});
  metaTags.push({name: 'og:image', content: image});
  metaTags.push({name: 'twitter:title', content: title});
  metaTags.push({name: 'twitter:site', content: '@observatoryzone'});
  metaTags.push({name: 'twitter:creator', content: '@observatoryzone'});
  metaTags.push({name: 'twitter:card', content: 'summary_large_image'});
  metaTags.push({name: 'twitter:image', content: image});

  if (description) {
    metaTags.push({name: 'description', content: description});
    metaTags.push({name: 'og:description', content: description});
    metaTags.push({name: 'twitter:description', content: description});
  }

  if (keywords.length > 0) {
    metaTags.push({name: 'keywords', content: keywords.join(', ')});
  }

  return metaTags;
}
